import React, { useRef } from "react";
import Navbar from "../src/Components/Navbar/Navbar.jsx";
import FormDataComponent from "./Components/FormData/FormData.js";
import FooterMy from "./Components/FooterMy/FooterMy.js";
import Home from "./Components/Home/Home.js";
import Scard from "./Components/Cards/Scard.js";
import MovieCardOne from "./Components/MovieCardOne/MovieCardOne.js";
import AboutUs from "./Components/AboutUS/AboutUs.js";
function App() {
  const formRef = useRef(null);

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Navbar />
      <AboutUs scrollToForm={scrollToForm} />
      <Scard />
      <Home />
      <div ref={formRef}>
        <FormDataComponent />
      </div>
      <MovieCardOne />

      <FooterMy />
    </div>
  );
}

export default App;
