import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <div className="HomeContainer">
      <div>
        <div class="container-fluid text-center">
          <div class="page-header">
            <h1></h1>
          </div>

          <div class="panel">
            <div class="panel-body"></div>
          </div>

          <div class="video-wrap">
            {/* <iframe
              width="560"
              height="315"
              src="https://youtu.be/aOpOIxivpqw?si=gdG8SXKLm2amaPtA"
              frameborder="0"
              allowfullscreen
            ></iframe> */}

            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/g3JUbgOHgdw?si=AArVJiKO5SHYE7Gx"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>

            {/* <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/kQDd1AhGIHk?si=dhnVmq7pNqqysMWV"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
