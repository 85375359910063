import React, { useState, useEffect } from "react";
import Rating from "react-rating";
import "@fortawesome/fontawesome-free/css/all.css";
import { toast } from "react-toastify"; // Importing React Toastify
import "react-toastify/dist/ReactToastify.css"; // Importing Toastify styles
import "./FormData.css";

const FormDataProd = () => {
  const scriptURL =
    "https://script.google.com/macros/s/AKfycbzi5rXLCSNGr9qOvY_vdKZJ-H_tSEmvvLwG4XsI5eilroNeVRZB329s1rkZBcwc-0e0/exec"; // Replace with your actual URL
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    movieName: "",
    language: "",
    tickets: "1",
    phoneNumber: "",
    totalTicketPrice: "",
    discountedPrice: "",
    image: null,
    imagePreview: null,
    persons: [],
    rating: 0,
    reviewMessage: "",
  });
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [cashbackPercentage, setCashbackPercentage] = useState(0);
  const [cashbackClicked, setCashbackClicked] = useState(false);
  const [showCashbackCard, setShowCashbackCard] = useState(false);
  const [shareCount, setShareCount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChangePrice = (e) => {
    const { name, value } = e.target;

    // Check if the value for totalTicketPrice is greater than 2000
    if (name === "totalTicketPrice" && parseFloat(value) > 2000) {
      setErrorMessage("Please enter an amount below 2000 only.");
    } else {
      setErrorMessage(""); // Clear the error message if valid
    }

    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const storedShareCount = localStorage.getItem("shareCount");
    if (storedShareCount) {
      setShareCount(parseInt(storedShareCount, 3));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("shareCount", shareCount);
  }, [shareCount]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePersonNameChange = (e, index) => {
    const persons = [...formData.persons];
    persons[index] = { ...persons[index], name: e.target.value };
    setFormData({ ...formData, persons });
  };

  const handlePersonNumberChange = (e, index) => {
    const persons = [...formData.persons];
    persons[index] = { ...persons[index], number: e.target.value };
    setFormData({ ...formData, persons });
  };

  const generatePersonFields = () => {
    let fields = [];
    for (let i = 1; i < formData.tickets; i++) {
      fields.push(
        <div key={i}>
          <label htmlFor={`personName${i}`} className="form-label">
            Person {i + 1} Name
          </label>
          <input
            type="text"
            id={`personName${i}`}
            name={`personName${i}`}
            className="form-control mb-2"
            onChange={(e) => handlePersonNameChange(e, i)}
            required
          />
          <label htmlFor={`personNumber${i}`} className="form-label">
            Person {i + 1} Number
          </label>
          <input
            type="tel"
            id={`personNumber${i}`}
            name={`personNumber${i}`}
            className="form-control mb-3"
            onChange={(e) => handlePersonNumberChange(e, i)}
            required
          />
        </div>
      );
    }
    return fields;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, image: file, imagePreview: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const calculateCashback = () => {
    if (formData.totalTicketPrice) {
      const totalTicketPrice = parseFloat(formData.totalTicketPrice);
      if (!isNaN(totalTicketPrice) && totalTicketPrice > 0) {
        const cashback = Math.random() * (20 - 10) + 10; // Generate random cashback between 10% and 20%
        const cashbackPercentage = cashback.toFixed(2); // Keep two decimal places
        const discountedPrice = (totalTicketPrice * cashbackPercentage) / 100;
        setCashbackPercentage(cashbackPercentage);
        setFormData({
          ...formData,
          discountedPrice: discountedPrice.toFixed(2),
        });
        setCashbackClicked(true); // Disable the button after calculating cashback
        setShowCashbackCard(true); // Show the cashback card
      } else {
        setCashbackPercentage(0); // Reset cashback percentage if input is invalid
        setFormData({ ...formData, discountedPrice: "" });
      }
    } else {
      setCashbackPercentage(0); // Reset cashback percentage if no input
      setFormData({ ...formData, discountedPrice: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("Submitting...");

    const submissionData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "persons") {
        formData.persons.forEach((person, index) => {
          if (person && person.name && person.number) {
            submissionData.append(`person${index + 1}Name`, person.name);
            submissionData.append(`person${index + 1}Number`, person.number);
          }
        });
      } else if (key === "image") {
        if (formData.image) {
          submissionData.append("image", formData.image, formData.image.name);
          submissionData.append("imageMimeType", formData.image.type);
        }
      } else if (key !== "imagePreview") {
        submissionData.append(key, formData[key]);
      }
    });

    try {
      const response = await fetch(scriptURL, {
        method: "POST",
        body: submissionData,
      });
      if (response.ok) {
        setMessage(
          "To avail your cashback, please share the link on WhatsApp.!"
        );
        setFormData({
          fullname: "",
          email: "",
          movieName: "",
          language: "",
          tickets: "1",
          phoneNumber: "",
          totalTicketPrice: "",
          discountedPrice: "",
          image: null,
          imagePreview: null,
          persons: [],
          rating: 0,
          reviewMessage: "",
        });
        setSubmitted(true);
        setCashbackClicked(false); // Reset cashback button for new form submission
        setShowCashbackCard(false); // Hide cashback card after submission
        setShareCount(0); // Reset share count
        localStorage.removeItem("shareCount"); // Clear share count from local storage
        // Scroll to the form section after submission
        const formElement = document.getElementById("form-section");
        if (formElement) {
          formElement.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        setMessage("Failed to submit. Please try again.");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  const handleShareWhatsApp = () => {
    window.open(
      "https://api.whatsapp.com/send?text=Here%20is%20the%20initiate%20offer%20from%20film%20producers%20to%20bring%20audience%20to%20theatre.%20Get%20reward,%20after%20watching%20the%20movie.%20Show%20your%20ticket%20details%20and%20avail%20the%20guaranteed%20prize%20money.%20Open%20the%20link%20and%20it%27ll%20take%20two%20mins%20to%20get%20the%20reward.%20www.filmproducers.in",
      "_blank"
    );
    setShareCount((prevCount) => prevCount + 1);
  };

  const handleRatingChange = (value) => {
    setFormData({ ...formData, rating: value });
  };

  const showModal = (e) => {
    e.preventDefault();
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="container text-center">
      <br />
      <h2 className="form-heading" style={{ color: "#fff" }}>
        Fill Your Details and Get a Chance to Win Cashback
      </h2>
      <div
        className="alert alert-warning"
        role="alert"
        style={{ color: "#000", fontFamily: "monospace" }}
      >
        Please ensure the accuracy of the provided name and mobile number.
        Cashback will be credited based on the provided details.
      </div>
      <br />
      <div className="mx-auto">
        <div className="card-body">
          {submitted ? (
            <div>
              <div
                className="alert alert-success mt-3"
                style={{ color: "#000", fontFamily: "monospace" }}
              >
                To avail your cashback, please share this link with 10 members
                and 5 groups in your WhastApp:
              </div>
              <button
                type="button"
                className="btn btn-primary mb-3"
                onClick={handleShareWhatsApp}
              >
                Share on WhatsApp
              </button>
              <p id="taskCompleteDetails">
                Shares: {shareCount} <span>/3</span>
              </p>
              {shareCount >= 3 ? (
                <div className="mt-3">
                  <p
                    className="alert alert-success"
                    style={{ color: "#000", fontFamily: "monospace" }}
                  >
                    Thank you for sharing! You have successfully availed the
                    cashback.
                  </p>
                </div>
              ) : (
                <p id="taskCompleteDetails">
                  Please complete {3 - shareCount} more shares to avail the
                  cashback.
                </p>
              )}
            </div>
          ) : (
            // FORM //

            <form id="form-section" onSubmit={handleSubmit}>
              <label name="movieName" className="labelName">
                Movie name
              </label>
              <div className="mb-3">
                <input
                  name="movieName"
                  type="text"
                  className="form-control"
                  placeholder="Movie Name"
                  value={formData.movieName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Language Watched</label>
                <input
                  name="language"
                  type="text"
                  className="form-control"
                  placeholder="Movie Language Watched"
                  value={formData.language}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Full Name</label>
                <input
                  name="fullname"
                  type="text"
                  className="form-control"
                  placeholder="Full Name"
                  value={formData.fullname}
                  onChange={handleChange}
                  required
                />
              </div>
              <label className="form-label">Phone number</label>
              <div className="mb-3">
                <input
                  name="phoneNumber"
                  type="tel"
                  className="form-control"
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Number of Tickets</label>
                <select
                  name="tickets"
                  className="form-control"
                  value={formData.tickets}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      tickets: e.target.value,
                      persons: [],
                    })
                  }
                >
                  {[...Array(6).keys()].map((i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
              </div>
              {generatePersonFields()}
              {/* <div className="mb-3">
                <label className="form-label">Ticket price</label>
                <input
                  name="totalTicketPrice"
                  type="number"
                  className="form-control"
                  placeholder="Total Ticket Price"
                  min="1"
                  max="2000"
                  value={formData.totalTicketPrice}
                  onChange={handleChange}
                  required
                />
              </div> */}
              <div className="mb-3">
                <label className="form-label">Ticket price</label>
                <input
                  name="totalTicketPrice"
                  type="number"
                  className="form-control"
                  placeholder="Total Ticket Price"
                  min="1"
                  max="2000"
                  value={formData.totalTicketPrice}
                  onChange={handleChangePrice}
                  required
                />
                {errorMessage && (
                  <div className="alert alert-danger mt-2" role="alert">
                    {errorMessage}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">Movie Ticket Photo </label>
                <input
                  name="image"
                  type="file"
                  className="form-control"
                  onChange={handleImageChange}
                />
                {formData.imagePreview && (
                  <div className="mt-3">
                    <img
                      src={formData.imagePreview}
                      alt="Preview"
                      className="img-fluid"
                    />
                  </div>
                )}
              </div>
              <button
                type="button"
                className="btn mb-3"
                onClick={calculateCashback}
                disabled={cashbackClicked}
              >
                Get Cashback
              </button>
              <div
                className={`card ${showCashbackCard ? "" : ""}`}
                onClick={() => setShowCashbackCard(!showCashbackCard)}
              >
                <p id="DiscountedPrice">
                  Discounted Price: {formData.discountedPrice}
                </p>
                <p>Thank you participating!!</p>
              </div>
              {/* ratings and review */}
              <div className="mb-3">
                <label className="form-label">Rate The Movie</label>
                <p className="ml-2">{formData.rating}</p>
                <Rating
                  initialRating={formData.rating}
                  emptySymbol={
                    <i className="far fa-star" style={{ color: "#ffc107" }} />
                  }
                  fullSymbol={
                    <i className="fas fa-star" style={{ color: "#ffc107" }} />
                  }
                  onChange={handleRatingChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Review Message</label>
                <textarea
                  name="reviewMessage"
                  className="form-control"
                  placeholder="Your Review Message"
                  value={formData.reviewMessage}
                  onChange={handleChange}
                  rows={3}
                />
              </div>
              {/* TERMS AND CONDITIONS */}
              <div className="tacbox">
                <input id="checkbox" type="checkbox" required />
                <label htmlFor="checkbox">
                  I agree to the{" "}
                  <a href="" id="Terms" onClick={showModal}>
                    Terms and Conditions
                  </a>
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-animated submit-btn"
              >
                Submit
              </button>
              {message && (
                <div className="mt-3 alert alert-info">{message}</div>
              )}
            </form>
          )}
        </div>
      </div>
      {/* TERMS AND CONDITIONS MODAL */}
      {isModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <h2 id="Terms">Terms and Conditions</h2>
            <p>
              To participate in our cashback rewards promotion, you need to
              provide your name, email, a picture of your movie ticket, and the
              ticket amount. After submitting this information, we will
              calculate a discounted price. Once we have carefully reviewed your
              details, you will receive a telephone call from one of our
              representatives. During this call, you must correctly answer the
              questions we ask to confirm your eligibility for the discounted
              price. If you successfully answer the questions, you may get the
              chance to receive the discounted price shown during your data
              submission. By participating, you agree to these terms and
              conditions. Enjoy the unique theater experience and your chance to
              earn cashback rewards on movie tickets!{" "}
            </p>
            {/* Add the actual terms and conditions content here */}
            <button onClick={closeModal} className="btn btn-primary">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormDataProd;
